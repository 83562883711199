<template>
    <AppPublicLayout>
        <template v-slot:header>
            <AppHeader />
        </template>
        <template v-slot:main>
            <div class="main-content">
                <div class="app-title">Массаж в <span>твоем</span> городе</div>
                <div class="page-title">Регистрация</div>
                <AppRegistrationForm @sendData="registration($event)" :loading="loading" />
                <transition name="popup">
                    <AppPopupMessage v-if="popup.isActive" @action="popup.closePopup" :title="popup.title" :text="popup.text" />
                </transition>
            </div>
        </template>
        <template v-slot:footer>
            <AppFooter />
            <AppNavigationMobile />
        </template>
    </AppPublicLayout>
</template>

<script>
import AppPublicLayout from "@/layouts/user/public/app-public-layout.vue";
import AppHeader from "@/sections/app-header.vue";
import AppFooter from "@/sections/app-footer.vue";
import AppNavigationMobile from "@/sections/app-navigation-mobile.vue";
import AppRegistrationForm from "@/components/forms/app-registration-form.vue";
import AppPopupMessage from "@/components/popups/app-popup-message.vue";
import registrationApi from "@/api/registration.js";
import { reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
    export default {
        components: {
            AppPublicLayout,
            AppHeader,
            AppFooter,
            AppNavigationMobile,
            AppRegistrationForm,
            AppPopupMessage
            
        },
        setup(){
            const store = useStore();
            const router = useRouter();

            const loading = ref(false);
            const popup = reactive({
                isActive: false,
                text: "",
                title: "",
                link: "",
                closePopup: () => {
                    popup.isActive = false;
                    router.push(`${popup.link}`);
                
                }
            })
            const registration = async function(data){
                 loading.value = true;
                 const user = await registrationApi.registration(data).catch(err => console.log(err));
                 loading.value = false;
                 if(user.data.status === 'massagist'){
                     store.dispatch('setUser', {
                         token: user.data.token,
                         status: user.data.status,
                         user: user.data.user,
                         finance: user.data.finance,
                         mainPhoto: 'user.png'
                     });
                    popup.isActive = true;
                    popup.title = `Поздравляем, ${user.data.user.username}! Вы успешно зарегистрировались на сайте!`;
                    popup.text = 'Сейчас вы будете перенаправлены в личный кабинет, где сможете заполнить анкету и добавить фотографии';
                    popup.link = `/massagist-personal-page/${store.state.user.user.id}/massagist-anket`;
                    console.log(store.state.user)
                    setTimeout(() => {
                        router.push(`/massagist-personal-page/${store.state.user.user.id}/massagist-anket`);
                    }, 4000)
                 } else if(user.data.status === 'saloon'){
                     store.dispatch('setUser', {
                         token: user.data.token,
                         status: user.data.status,
                         user: user.data.user
                     });
                    popup.isActive = true;
                    popup.title = `Поздравляем, ваш салон ${user.data.user.username} успешно зарегистрирован на сайте!`;
                    popup.text = 'Сейчас вы будете перенаправлены в личный кабинет, где сможете заполнить анкету и добавить фотографии';
                    popup.link = `/massagist-personal-page/${store.state.user.user.id}/massagist-anket`;
                        
                    setTimeout(() => {
                        router.push(`/massagist-personal-page/${store.state.user.user.id}/massagist-anket`);
                    }, 4000);
                 } else if(user.data.status === 'client'){
                     store.dispatch('setUser', {
                         token: user.data.token,
                         status: user.data.status,
                         id: user.data.user.id
                     });
                    popup.isActive = true;
                    popup.title = `Поздравляем, ${user.data.user.name}! Вы успешно зарегистрировались на сайте!`;
                    popup.text = 'Теперь вы можете отсавлять отзывы о массажистах и массажных салонах, отправлять личные сообщения, ставить оценки, добавлять в избранное. На сайте действует модерация, любые агрессивные действия влекут блокировку';
                    popup.link = '/';
                     setTimeout(() => {
                        router.push(`/`);
                    }, 4000)
                 } else if(user.data === 'ER_DUP_ENTRY'){
                     popup.isActive = true;
                     popup.title = "Пользователь с таким номером телефона уже есть в базе!";
                     popup.text = "Если вы забыли пароль от аккаунта, воспользуйтесь сервисом восстановления паролей";
                     popup.link = "/drop-password";
                     setTimeout(() => {
                        router.push(`/drop-password`);
                    }, 4000)
                 }
             }

           

            return {
                registration,
                loading,
                popup
            }
        }
    }
</script>

<style lang="scss" scoped>
    .popup-enter-active{
        transition: 0.2s ease;
    }
    .popup-enter-from{
        transform: translate(-50%, -50%) scale(0);
    }
    .popup-enter-to{
         transform: translate(-50%, -50%) scale(1);
    }
    .popup-leave-active{
        transition: 0.2s ease;
    }
    .popup-leave-from{
         transform: translate(-50%, -50%) scale(1);
    }
    .popup-leave-to{
         transform: translate(-50%, -50%) scale(0);
    }

    .main-content{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .app-title{
            font-size: 1.5em;
            font-weight: 700;
            color: $black;
            span{
                color: $accent-dark;
            }
        }
        .page-title{
            font-size: 2.2em;
            color: $black;
            font-weight: 700;
            text-transform: uppercase;
            margin: 1em 0;
        }
        .registration-form{
            width: 80%;
            .conditions{
                display: flex;
                justify-content: flex-start;
                .conditions-label{
                    margin-left: -90px;
                    color: $black;
                    font-weight: 700;
                    a{
                        text-decoration: none;
                        color: $accent-dark;
                    }
                }
            }
            .registration-btn{
                width: 100%;
                text-align: center;
                padding: 2em 0;
            }
    }
    }

    @media screen and(min-width: 768px){
        .main-content{
            width: 60%;
            margin: 0 auto;
        }
    }
    @media screen and(min-width: 1280px){
        .main-content{
            width: 30%;
            margin: 0 auto;
        }
    }
</style>