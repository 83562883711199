<template>
    <div class="registration-form">                    
        <AppRadioButton v-for="radio, index in formOptions.radio" :key="index" :options="radio" @action="registrationData.getData.person($event)" />
        <BaseInput 
            outlined 
            rounded 
            validation="autocomplete" 
            autocomplete id="city" 
            label="Ваш город" 
            required 
            :autocompleteData="formOptions.city.autocompleteData" 
            :prefiltredData="formOptions.city.prefiltredData" 
            width="320"
            margin="0 0 50px 0"
            @sendData="registrationData.getData.city($event)" />
        <BaseInput outlined rounded validation="name" id="name" v-if="registrationData.person !== 'saloon'" label="Ваше имя" required width="320" margin="0 0 50px 0" @sendData="registrationData.getData.name($event)" />
        <BaseInput outlined rounded validation="name" id="name" v-if="registrationData.person === 'saloon'" label="Название салона" required width="320" margin="0 0 50px 0" @sendData="registrationData.getData.name($event)" />
        <BaseInput outlined rounded validation="phone" id="phone" label="Ваш номер телефона" required width="320" margin="0 0 50px 0" @sendData="registrationData.getData.phone($event)" />
        <BaseInput outlined rounded validation="password" id="password" label="Придумайте пароль" required width="320" margin="0 0 50px 0" inputType="password" @sendData="registrationData.getData.password($event)" />
        <BaseInput outlined rounded validation="repeate" :password="registrationData.password" id="repeatPassword" label="Повторите пароль" required width="320" margin="0 0 50px 0" inputType="password" @sendData="registrationData.getData.repeatePassword($event)" />
        
        <div class="conditions">
            <AppCheckInput :options="formOptions.checkConditions" @checked="registrationData.getData.conditionsAccepted($event)" />
            <span class="conditions-label">Принимаю 
                <a target="_blank" href="http://erm21.net/rules">правила сайта</a>, условия 
                <a href="http://erm21.net/agreement" target="_blank">пользовательского соглашения</a> и 
                <a href="http://erm21.net/confidentials" target="_blank">политики конфиденциальности</a></span>            
        </div>
        <div class="registration-btn">
            <AppRegularBtn dark text="регистрация" :disabled="!valid" @action="registrationData.sendData" :loading="loading"/>
        </div>
    </div>
</template>

<script>
import AppRadioButton from "@/components/inputs/app-radio-input.vue";
import AppCheckInput from "@/components/inputs/app-check-input.vue";
import AppRegularBtn from "@/components/buttons/app-regular-btn.vue";
import cities from "@/database/cities.js";
import { reactive, computed } from 'vue';
    export default {
        components: {
            AppRadioButton,
            AppCheckInput,
            AppRegularBtn
        },
        props: {
            loading: {
                type: Boolean,
                default: false
            }
        },
        setup(props, { emit }){
            const formOptions = {
                radio: [
                    {
                        id: "massagist",
                        label: "Я массажист(-ка)",
                        name: "person",
                        checked: true
                    },
                    {
                        id: "saloon",
                        label: "Я массажный салон",
                        name: "person",
                        disabled: false
                    },
                    // {
                    //     id: "client",
                    //     label: "Я клиент",
                    //     name: "person",
                    //     disabled: false
                    // }
                ],
                city: {
                    autocompleteData: cities,
                    prefiltredData: [
                        'Москва',
                        'Санкт-Петербург',
                        'Новосибирск',
                        'Казань'
                    ]
                },
                checkConditions: {
                    label: "",
                    id: "conditions",
                    name: 'conditions'
                }
            };
            const registrationData = reactive({
                person: "massagist",
                city: "",
                name: "",
                phone: "",
                password: "",
                repeatePassword: "",
                isConditionsAccepted: false,
                getData: {
                    person: (data) => registrationData.person = data,
                    city: (data) => registrationData.city = data,
                    name: (data) => registrationData.name = data,
                    phone: (data) => registrationData.phone = data,
                    password: (data) => registrationData.password = data,
                    repeatePassword: (data) => registrationData.repeatePassword = data,
                    conditionsAccepted: (data) => registrationData.isConditionsAccepted = data.checked
                },
                sendData: () => {
                    const dataToSend = {
                        person: registrationData.person,
                        city: registrationData.city,
                        name: registrationData.name,
                        phone: registrationData.phone,
                        password: registrationData.password
                    };
                    emit('sendData', dataToSend);
                }
            })

            const valid = computed(() => {
                let isValid = false;
                if(
                    registrationData.city.length > 0 &&
                    registrationData.name.length > 0 &&
                    registrationData.phone.length > 0 &&
                    registrationData.password === registrationData.repeatePassword &&
                    registrationData.isConditionsAccepted
                ) {
                    isValid = true;
                }

                return isValid;
            })

            return{
                formOptions,
                registrationData,
                valid
            }
        }
    }
</script>

<style lang="scss" scoped>
        .registration-form{
            .conditions{
                display: flex;
                justify-content: flex-start;
                .conditions-label{
                    margin-left: -90px;
                    color: $black;
                    font-weight: 700;
                    a{
                        text-decoration: none;
                        color: $accent-dark;
                    }
                }
            }
            .registration-btn{
                width: 100%;
                text-align: center;
                padding: 2em 0;
            }
    }
</style>