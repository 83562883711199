<template>
    <div class="app-public-layout">
        <header>
            <slot name="header"></slot>
        </header>        
        <main>
            <slot name="main"></slot>
        </main>       
        <footer>
            <slot name="footer"></slot>
        </footer>
    </div>
</template>


<style lang="scss" scoped>
    .app-public-layout{
        width: 100%;
        max-width: 1600px;
        margin: 0 auto;
        display: grid;
        grid-template-rows: repeat(6, auto);
        grid-template-columns: repeat(12, auto);
        // grid-column-gap: 10%;
        header{
            grid-row: 1 / 2;
            grid-column: 1 / 13;
        }
        main{
            grid-row: 2 / 5;
            grid-column: 1 / 13;
        }        
        footer{
            grid-row: 5 / 6;
            grid-column: 1 / 13;
        }
    }
</style>