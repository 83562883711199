<template>
    <div class="app-popup-message">
        <h2 class="popup-titile">{{ title }}</h2>
        <p class="popup-text">{{ text }}</p>
        <AppRegularBtn text="Понятно" dark @action="$emit('action')" />
    </div>
</template>

<script>
import AppRegularBtn from "@/components/buttons/app-regular-btn.vue";
    export default {
        components:{
            AppRegularBtn
        },
        props: {
            title: {
                type: String,
                default: ""
            },
            text: {
                type: String,
                default: ""
            }
        }
    }
</script>

<style lang="scss" scoped>
    .app-popup-message{
        width: 80%;
        height: 60%;
        max-width: 500px;
        max-height: 500px;
        background-color: $primary;
        border: 2px solid $accent-dark;
        border-radius: 0.5em;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        z-index: 100000;
        .popup-titile{
            text-align: center;
        }
        .popup-text{
            text-align: center;
        }
    }
</style>